import {html, LitElement} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {searchFlyoutStyles} from "@/components/search-flyout/search-flyout.styles.ts";
import {SbkLink} from "@/components/link/link";


@customElement('sbk-search-flyout')
export class SbkSearchFlyout extends LitElement {

    @property({type: Boolean, reflect: true})
    expanded = false;

    @property({type: Boolean})
    onScroll = false;

    @state()
    hideSearchSuggestion = false;

    static get styles() {
        return searchFlyoutStyles;
    }

    firstUpdated() {
        const searchInput = this.querySelector('sbk-search');
        searchInput?.setAttribute('has-dropdown', 'true');
        searchInput?.setAttribute('in-flyout', 'true');
    }

    connectedCallback() {

        super.connectedCallback();
        document.addEventListener('keydown', this._handleKeyDown);
        this.addEventListener('triggerHideSearchSuggestion', this._triggerHideSearchSuggestion)
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('keydown', this._handleKeyDown);
        this.removeEventListener('triggerHideSearchSuggestion', this._triggerHideSearchSuggestion)
    }

    private _handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && this.expanded) {
            this._handleTriggerClick(event)
        }
    };

    render() {
        return html`
            <slot class="search-trigger" name="trigger" @click=${this._handleTriggerClick}></slot>
            <div class="search-flyout ${this.expanded ? 'expanded' : ''} ${this.onScroll ? 'on-sroll' : ''}">
                <div class="search-flyout__controls">
                    <sbk-round-button class="search-flyout__controls-close" icon="close"
                                      @click=${this._handleTriggerClick}>X
                    </sbk-round-button>
                </div>
                <div class="search-flyout__content-wrapper">
                    <div class="search-wrapper">
                        <slot></slot>
                    </div>
                    <div class="search-suggestion-wrapper ${this.hideSearchSuggestion ? 'hidden' : ''}">
                        <slot name="searchSuggestion"></slot>
                    </div>
                </div>
            </div>
            <div class="nav-flyout-backdrop ${this.expanded ? 'expanded' : ''}"
                 @click=${this._handleTriggerClick}></div>
        `;
    }

    _handleTriggerClick(event: Event) {
        event.preventDefault()
        this.expanded = !this.expanded
        this._changeTriggerElement()
        const triggeredFlyout = new CustomEvent('triggeredSearchFlyout', {
            bubbles: true,
            composed: true,
            detail: {
                expanded: this.expanded
            }
        })
        this.dispatchEvent(triggeredFlyout)
        document.body.classList.toggle('no-scroll', this.expanded);
    }

    _closeFlyout(event: Event) {
        const id = (<CustomEvent>event).detail?.id
        if (id !== this.id) {
            this.expanded = false
        }
    }

    _changeTriggerElement() {
        const link = this.querySelector('sbk-link[slot="trigger"]') as SbkLink
        if (link) {
            link.setAttribute('aria-expanded', String(this.expanded))
            if (this.expanded && link.variant === 'icon-navigation') {
                link.icon = 'close-round'
            } else if (link.variant === 'icon-navigation') {
                link.icon = 'search'
            }
            link.isSelected = this.expanded;
        }
    }

    _triggerHideSearchSuggestion(event: Event) {
        this.hideSearchSuggestion = (<CustomEvent>event).detail?.hideSearchSuggestion
    }
}
