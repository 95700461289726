import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const searchFlyoutStyles = css`
    :host {
        --search-flyout-control-width: calc((100% / 12) * 10);
        --search-flyout-list-width: 100%;

        @media all and (min-width: ${breakpointMd}) {
            --search-flyout__standart-lg-width: 400px;
        }

        @media all and (min-width: ${breakpointLg}) {
            --search-flyout-list-width: calc((100% / 12) * 8);
        }
    }

    ::slotted(sbk-link) {
        width: 100%;
        vertical-align: unset;
    }

    .search-flyout {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: var(--sbkSearchFlyoutColorBgColorOnScroll);
        padding-top: var(--sbkSearchFlyoutSpacingViewportSmInsetTop);
        padding-bottom: var(--sbkSearchFlyoutSpacingViewportSmInsetBottom);
        z-index: 15;

        @media all and (min-width: ${breakpointMd}) {
            padding-top: var(--sbkSearchFlyoutSpacingViewportMdInsetTop);
            padding-bottom: var(--sbkSearchFlyoutSpacingViewportMdInsetBottom);
            left: unset;
            right: 0;
            width: var(--sbkNavFlyoutSizingLgFixedWidth);
            top: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight);
            background-color: #ffffff;
            height: calc(100vh - var(--sbkTopNavBarSizingLargeScreenFixedBarHeight));
            overflow-x: hidden;
            overflow-y: auto;
        }

        @media all and (min-width: ${breakpointLg}) {
            padding-top: var(--sbkSearchFlyoutSpacingViewportLgInsetTop);
            padding-bottom: var(--sbkSearchFlyoutSpacingViewportLgInsetBottom);
        }

        @media all and (min-width: ${breakpointXl}) {
            padding-top: var(--sbkSearchFlyoutSpacingViewportXlInsetTop);
            padding-bottom: var(--sbkSearchFlyoutSpacingViewportXlInsetBottom);
            left: 0;
            width: 100%;
            min-height: 50vh;
            height: fit-content;
        }

    }

    .nav-flyout-backdrop {
        display: none;
    }

    .nav-flyout-backdrop.expanded {
        display: block;
        position: fixed;
        top: 102px;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        background-color: var(--sbkNavFlyoutColorBackdrop);

        @media all and (max-width: ${breakpointXl}) {
            top: var(--sbkTopNavBarSizingSmallScreenFixedBarHeight)
        }
    }

    .nav-flyout.on-scroll {
        background: var(--sbkNavFlyoutColorBgColorOnScroll);
    }

    .search-flyout.expanded {
        display: block;
    }

    .search-flyout__content-wrapper {
        position: relative;
        padding: 0 var(--sbkCoreGridSmMargin);
        @media all and (min-width: ${breakpointMd}) {
            padding: 0 var(--sbkCoreGridMdMargin);
        }
    }

    .search-suggestion-wrapper {
        margin: 0 auto;
        max-width: var(--sbkCoreContentAreaBodyMaxWidth);
    }

    .search-suggestion-wrapper.hidden {
        visibility: hidden;
    }

    .search-flyout__controls {
        display: flex;
        justify-content: flex-end;
        width: var(--search-flyout-control-width);
    }

    .search-flyout__controls-close {
        @media all and (max-width: ${breakpointXl}) {
            display: none;
        }
    }
`;
